<!--
 * @Author: 月魂
 * @Date: 2021-03-24 16:07:05
 * @LastEditTime: 2021-04-30 16:14:27
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Personal.vue
-->
<template>
  <div class="box">
     <van-nav-bar
      title="个人信息"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <div class="info">
      <img :src="user.avatar" class="avatar">
      <div class="nickname">{{ user.nickName }}</div>
    </div>
    <div class="mobile">
      <div v-if="!user.mobile">
        <span>您尚未完成手机号验证, </span>
        <span class="editMobile" @click="jumpToMobile">完成验证 > </span>
      </div>
      <div v-else>
        <span>{{ user.mobile }} </span>
        <span class="editMobile" @click="jumpToMobile">修改手机号 > </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon } from 'vant';
Vue.use(NavBar).use(Icon);

export default {
  name: 'Personal',
  data() {
    return {
      user: {
        avatar: '',
        nickName: '',
        mobile: ''
      },
    }
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'));
    this.user = info
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    jumpToMobile() {
      this.$router.push('/mobile')
    }
  },
}
</script>

<style lang="less">
.box {
  height: 100%;
  background-color: #f9f9f9;
  .info {
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px 0 10px 10px;
    display: flex;
    align-items: center;
    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50% 50%;
      margin-right: 10px;
    }
    .nickname {
      font-size: 18px;
      color: #333;
    }
  }
  .mobile {
    padding-left: 84px;
    background-color: #fff;
    font-size: 14px;
    color: #7f7f7f;
    line-height: 2;
    padding-bottom: 10px;
    .editMobile {
      color: red;
    }
  }
}
</style>